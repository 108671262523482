import { createSlice } from '@reduxjs/toolkit'

export const updateTicketSlice = createSlice({
    name:"updateTicket",
    initialState: {
        jobId: "",
        employee: [],
        expensData: [],
        total: "",
        equipment: [],
        markup: ["0"],
        date: "",
        comments: "",
        location: "",
        materialData : [],
        ticket_id: ""

    },
    reducers: {

       setUpdateTicketData:(state, action)=>{
            const newData = action.payload;

            // Loop through the keys in newData and update the state properties
            Object.keys(newData).forEach((key) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = newData[key];
                }
            });
       },

       getUpdateTicketData:(state, action)=>{
        
       }

    }
})

export const { setUpdateTicketData } = updateTicketSlice.actions

export default updateTicketSlice.reducer