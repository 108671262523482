import { createSlice } from '@reduxjs/toolkit'

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        expire_date: "",
        days: 0,
        notSubmittedTickets: 0,
        notApprovedTickets: 0,
        pendingTickets: 0,
        approvedTickets: 0,
        rejectedTickets: 0,
        submittedTickets: 0,
        percent_remaining: 0,
        percent_consumed: 0,
        jobs:[],
        isBasicPlan:"",
        data:[]

    },
    reducers: {

       setTicketData:(state, action)=>{
            const newData = action.payload;
            console.log(newData)

            // Loop through the keys in newData and update the state properties
            Object.keys(newData).forEach((key) => {
                if (state.hasOwnProperty(key)) {
                    state[key] = newData[key];
                }
            });
       },

       getTicketData:(state, action)=>{
        
       }

    }
})

export const { setTicketData } = ticketSlice.actions

export default ticketSlice.reducer