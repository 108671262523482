import axios from "axios"

function getUrlParameter(name) {
  // Get the current URL
  const url = window.location.href;

  // Use a regular expression to find the parameter value
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null; // Parameter not found
  }

  if (!results[2]) {
    return ''; // Parameter exists but has no value
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const checkAuth = () => {
/*  Getting token value stored in localstorage, if token is not present we will open login page 
    for all internal dashboard routes  */
    const userToken = getUrlParameter('userToken');

    if (userToken !== null) {
      // console.log('userToken:', userToken);
      localStorage.setItem("token", userToken)
    } else {
      // console.log('userToken parameter is not present in the URL');
    }
    

    const TOKEN = localStorage.getItem("token")
    const LANGUAGE = localStorage.getItem("i18nextLng")
    // console.log("TOKEN", TOKEN)
    const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]

    const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))

    if(!TOKEN && !isPublicPage){
        window.location.href = '/login'
        return;
    }else{
        axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
        axios.defaults.headers.common['Accept-Language'] = LANGUAGE == 'english' ? 'en' : 'fr'

        axios.interceptors.request.use(function (config) {
            // UPDATE: Add this code to show global loading indicator
            document.body.classList.add('loading-indicator');
            return config
          }, function (error) {
            return Promise.reject(error);
          });
          
          axios.interceptors.response.use(function (response) {
            // UPDATE: Add this code to hide global loading indicator
            document.body.classList.remove('loading-indicator');
            return response;
          }, function (error) {
            console.log("ERRORR", error.response.statusText)
            let unAuthorized = error.response.statusText;

            if(unAuthorized == 'Unauthorized'){
              localStorage.removeItem('token');
              // window.Location
            }
            document.body.classList.remove('loading-indicator');
            return Promise.reject(error);
          });
        return TOKEN
    }
}

export default checkAuth