import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { Provider } from "react-redux";
import SuspenseContent from "./containers/SuspenseContent";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { GoogleOAuthProvider } from '@react-oauth/google';

import enTranslation from "./locales/en.json";
import frTranslation from "./locales/fr.json";
import { I18nextProvider } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      english: {
        translation: enTranslation,
      },
      french: {
        translation: frTranslation,
      },
    },
    fallbackLng: "english", // Default language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<SuspenseContent />}>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
     <GoogleOAuthProvider clientId="889096588113-7fgaujfu81hag5m8n8knk55mt19ti2kf.apps.googleusercontent.com">
            <App />
     </GoogleOAuthProvider>
          </I18nextProvider>
        </Provider>
    

  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
