import axios from "axios";
import { API_BASE_URL } from "../config";
const getLanguageFromLocalStorage = () => {
  // Replace 'i18nextLang' with the actual key you use to store language in local storage
  const storedLanguage = localStorage.getItem('i18nextLng');
  console.log(storedLanguage)
  // Modify logic to handle different cases
  switch (storedLanguage) {
    case 'english':
      return 'en';
    case 'french':
      return 'fr';
    // Add more cases as needed
    default:
      return 'en'; // Default to English if language is not explicitly handled
  }
};

const setLanguageInHeaders = () => {
  const language = getLanguageFromLocalStorage();
  axios.defaults.headers.common['Accept-Language'] = language;
};

export const getRateData = async () => {
  setLanguageInHeaders();
  const response = await axios.get(`${API_BASE_URL}rate/data`);
  return response;
};

export const getAll = async (data) => {
  setLanguageInHeaders();
  const response = await axios.get(`${API_BASE_URL}combine/getAll`, {
    params: data,
  });
  return response;
};


export const getBasicDetailsData = async () => {
  setLanguageInHeaders();
  const response = await axios.get(`${API_BASE_URL}app-basic-details`);
  return response;
};


export const postUploadImage = async (file) => {

  const formData = new FormData();
  formData.append('image', file);

  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  setLanguageInHeaders();
  const response = await axios.post(`${API_BASE_URL}upload-image`, formData, headers);
  return response;
}
