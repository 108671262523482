import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import ticketSlice from '../features/common/ticketSlice'
import updateTicketSlice from '../features/common/updateTicketSlice'

const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  ticket: ticketSlice,
  updateTicket: updateTicketSlice
}

export default configureStore({
    reducer: combinedReducer
})