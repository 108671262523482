import axios from "axios"
import { getBasicDetailsData } from "../api/combine";

const initializeApp = () => {

    getBasicDetailsData().then(data => {
        console.log("DATA",data.data.data)
        localStorage.setItem("basic", JSON.stringify(data.data.data));
    }).catch(erroe => console.log("Error"))

    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code



    } else {
        // Prod build code



        // Removing console.log from prod
        console.log = () => { };


        // init analytics here
    }

}

export default initializeApp